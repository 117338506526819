/*** FONT FAMILIES AND FONT SIZES ***/
/*** BOOTSTRAP OPTIONS ***/
/*** SEMANTIC COLOR SCHEME ***/
/*** OTHER BOOTSTRAP VARIABLES ***/
/*** CUSTOM DSPACE VARIABLES ***/
/*** MISC ***/
body {
  font-family: "AktivGroteskRg", Helvetica, sans-serif !important;
}
body {
  font-weight: 400;
  font-size: 14px;
}
.sidebar-content h3 {
  font-size: 1.3rem;
  color: #545b61;
}
#main-site-navigation {
  font-family: "emstSans", sans-serif;
  text-transform: uppercase;
  padding-bottom: 0.7em;
}
#main-site-navigation a {
  color: #000 !important;
}
@media (min-width: 768px) {
  #main-site-navigation.align-items-md-stretch {
    align-items: flex-end !important;
  }
}