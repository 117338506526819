/*** FONT FAMILIES AND FONT SIZES ***/
/*** BOOTSTRAP OPTIONS ***/
/*** SEMANTIC COLOR SCHEME ***/
/*** OTHER BOOTSTRAP VARIABLES ***/
/*** CUSTOM DSPACE VARIABLES ***/
/*** MISC ***/
.ds-full-screen-loader {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@font-face {
  font-family: "emstSans";
  src: url("../assets/fonts/EMSTSans_Ex.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "emstSansBlk";
  src: url("../assets/fonts/EMSTSans_ExBlk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AktivGrotesk";
  src: url("../assets/fonts/AktivGrotesk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "emstSansBlk";
  src: url("../assets/fonts/EMSTSans_ExBlk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AktivGroteskRg";
  src: url("../assets/fonts/AktivGrotesk_Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}